import * as React from "react";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { section } from "./privacy.module.css";

const PrivacyPolicyPage = ({ data }) => {
  return (
    <Layout pageTitle="Privacy Policy">
      <Container className={section}>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    mdx(frontmatter: { name: { eq: "Privacy Policy" } }) {
      frontmatter {
        name
      }
      body
    }
  }
`;

export default PrivacyPolicyPage;
